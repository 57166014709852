
.domain-chooser {
    width: max-content;
    margin-bottom: 25px;
    margin-top: 25px;
}

#shared-file-header {
    display: grid;
    grid-template-columns: 5fr 3fr 2fr 1fr 1fr;
    padding-left: 8px;

    p {
        margin: 0;
    }

    @media (max-width: 640px){
        display: none;
    }
}

#shared-file-line {
    display: grid;
    grid-template-columns: 5fr 3fr 2fr 1fr 1fr;
    max-width: 100%;

    > div:nth-child(2){
        word-break: break-all;
    }

    @media (max-width: 640px){
        grid-template-columns: 1fr;
        margin-bottom: 10px;
        border-bottom: black 2px solid;
    }
}


#fcm {
    position: fixed;
    background: lightgray;
    border: 1px solid black;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    z-index: 99999;
    padding: 5px;
    width: 115px;

    :hover {
        background-color: rgba(13, 113, 228, 0.4);
        text-decoration: underline;
        font-weight: 600;
        cursor: pointer;
    }
}


// request tab
#request-list {
    overflow-y: scroll;
    max-height: calc(100dvh - var(--footer-height) - 75px - 42px);

    h3 {
        text-decoration: underline;
    }

    #sorting-div {
        margin-top: 20px;
    }
}

#shared-file-header2 {
    display: grid;
    grid-template-columns: 3fr 6fr 1fr 2fr;
    padding-left: 8px;

    p {
        margin: 0;
    }

    @media (max-width: 640px){
        display: none;
    }
}

#shared-file-line2 {
    display: grid;
    grid-template-columns: 3fr 6fr 1fr 2fr;
    max-width: 100%;
    
    > div:nth-child(1){
        word-break: break-all;
    }

    @media (max-width: 640px){
        grid-template-columns: 1fr;
        margin-bottom: 10px;
        border-bottom: black 2px solid;
    }
}

#shared-request-btns {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
