/* FolderModal.module.scss */

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* High z-index to appear above other elements */
}

.modal {
    background-color: #fff;
    border-radius: 8px;
    width: 500px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    position: relative; /* Ensure positioning context */
    z-index: 10000; /* Ensure it's above the overlay if needed */
}

.modalHeader {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
}

.backButton {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    align-items: center;
    padding: 5px;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px #176ad6;
    }
}

.modalTitle {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}

.modalContent {
    padding: 15px;
    overflow-y: auto;
    flex-grow: 1;
}

.currentPath {
    font-size: 14px;
    color: #555;
    margin-bottom: 10px;
}

.folderList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.folderItem {
    margin-bottom: 10px;
}

.folderButton {
    display: flex;
    align-items: center;
    background: none;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    padding: 8px;
    width: 100%;
    text-align: left;
    transition: background-color 0.2s ease, border-color 0.2s ease;

    &:hover {
        background-color: #f0f0f0;
        border-color: #176ad6;
    }

    &:focus {
        outline: none;
        border-color: #176ad6;
        box-shadow: 0 0 0 2px rgba(23, 106, 214, 0.3);
    }
}

.iconFolder {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    color: #ffc107; /* Folder icon color */
}

.modalActions {
    display: flex;
    justify-content: flex-end;
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
}

.confirmButton,
.closeButton {
    padding: 8px 16px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s ease;
}

.confirmButton {
    background-color: #176ad6;
    color: white;

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }

    &:hover:not(:disabled) {
        background-color: #145bb5;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(23, 106, 214, 0.3);
    }
}

.closeButton {
    background-color: #999;
    color: white;

    &:hover {
        background-color: #777;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(153, 153, 153, 0.3);
    }
}

.iconBlue {
    color: #176ad6; /* Blue color */
}
