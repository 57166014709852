.dashboard-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 10px;
	margin: 10px auto 0;
	height: calc(100vh - 150px);
	width: 100%;
	overflow: hidden;

	@media (max-width: 1024px) {
		/* Tablet view - adjust for header */
		height: calc(100vh - 170px);
		max-width: calc(100% - 10px);
		margin-left: 5px;
		margin-right: 5px;
	}

	@media (max-width: 768px) {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(4, minmax(200px, auto));
		height: auto;
		padding-bottom: 20px;
		margin-top: 10px;
		gap: 10px;
	}

	h2 {
		font-size: 18px;
		font-weight: bold;
		text-decoration: underline;
		text-decoration-color: #176AD6;
		text-underline-offset: 0.4em;
		margin-bottom: 12px;
		text-align: left;

		@media (max-width:576px) {
			text-align: center;
		}
	}


	&>div {
		background-color: rgba(227, 232, 235, 0.5);
		padding: 12px;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		height: 100%;
		box-sizing: border-box;

		ul {
            margin: 0;
			padding: 0;
			list-style-type: none;
			overflow-y: auto;
			flex: 1;

			li {
				margin-bottom: 10px;
				white-space: normal;
				overflow: hidden;
				text-overflow: ellipsis;
				line-height: 1.2;

				p {
					font-size: 12px;
					color: gray;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin-top: 2px;
					margin-bottom: 0;

					&.message {
						color: black;
						font-size: 12px;
					}
				}
			}
		}
	}

	&>div:nth-child(4) {

		ul {
			margin-bottom: 0;
		}
	}
}