#messenger-title {
    margin-bottom: 20px;
}

#messenger {
    display: flex;
    width: 100%;

    > [id$=contacts] {
        background: white;
        grid-row-start: 1;
        grid-row-end: span 3;
    
        height: 100%;
    }
   
    > [id^=empty]{
        border-left: none;
    }
}

#messages {
    flex: 1;
}


// MessageList
#message-list {
    height: calc(100vh - 18rem);
    overflow-y: auto;
    padding-left: 1rem;
    border-left: solid lightgray 1px;

    @media (max-width: 1024px) {
        height: calc(100vh - 21rem);
    }

    @media (max-width: 767px) {

        height: calc(100vh - 33rem);
    }
}
#group-message-list {
    height: calc(100dvh - var(--footer-height) - 140px - 60px - 3.5px - 100px); 
}
#message-list-container {
    padding: 0px 3px 0px 3px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
}

#group-header {
    display: flex;
    justify-content: space-between;
    height: 100px;
    border-bottom: solid lightgray 1px;
    background-color: rgba($color: lightgrey, $alpha: 0.2);
}
#group-credentials{
    margin-left: 15px;
    margin-top: 15px;

    width: calc(100% - 115px);
}
#group-title{
    font-weight: 800;
    margin-bottom: 10px;
}
#group-participants{
    overflow-x: scroll;
    margin: 0;
    height: 46px;
}
#group-sender {
    color: #4b4949;
    font-weight: 600;
    font-size: 12px;
    margin: 0px 0px 5px 10px;
}
#group-rename {
    height: min-content;
    width: min-content;
    margin: auto 0;
}


// Message
#message {
    display: flex;
    flex-direction: column;
}
#timestamp {
    display: flex;
    justify-content: center;
    color: #999;
    font-weight: 600;
    font-size: 12px;
    margin: 10px 0px;
    text-transform: uppercase;
}
#bubble-container {
    font-size: 14px;
    display: flex;
}
[id^=message] > #bubble-container > #bubble {
    // common bubble styles
    margin: 1px 0px;
    max-width: 75%;
    padding: 10px 15px;
    hyphens: auto;
    overflow-x: hidden;

    background: #f4f4f8;
    border-radius: 20px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}
[id^=message-mine] > #bubble-container > #bubble {
    background: #007aff;
    color: white;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
[id^=message-mine] > #bubble-container {
    justify-content: flex-end;
}
[id^=message-start] > #bubble-container > #bubble {
    border-top-left-radius: 15px;
}
[id=message-end] > #bubble-container > #bubble {
    border-bottom-left-radius: 15px;
}
[id=message-start-end] > #bubble-container > #bubble {
    border-bottom-left-radius: 15px;
}
[id^=message-mine-start] > #bubble-container > #bubble {
    margin-top: 10px;
    border-top-right-radius: 15px;
}
[id^=message-mine][id$=end] > #bubble-container > #bubble {
    border-bottom-right-radius: 15px;
    margin-bottom: 10px;
}


// Compose
#compose {
    padding: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 17rem;

    background-color: white;
    border: 1px solid lightgray;
    border-radius: var(--bs-border-radius); //0.375rem
}
#compose-input {
    display: flex;
    border: none;
    font-size: 14px;
    height: 40px;
    background: none;
    border-radius: 8px;
    width: 100%;
    margin-right: 10px;
}



// ConversationList
#conversation-list {
    display: flex;
    flex-direction: column;
    max-height: 100%;
}
#conversation-list-contacts {
    overflow: auto;
    max-height: 39rem;

    @media screen and (max-width: 767px) {
        // overflow: auto;
        max-height: 9rem;
    }
}

// ConversationSearch
#conversation-search {
    padding: 10px;
    display: flex;
}
#conversation-search-input {
    background: #f4f4f8;
    padding: 8px 10px;
    border-radius: 10px;
    border: none;
    font-size: 14px;
    margin-left: 10px;
    width: 100%;
}
#conversation-search-input::placeholder {
    text-align: center;
}
#conversation-search-input:focus::placeholder {
    text-align: left;
}
// NewChatModal
#new-chat-modal {
    border-top: solid black 2px;
    border-bottom: solid black 2px;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 10px 5px;

    input {
        border-radius: 3px;
        font-size: 14px;
        margin-bottom: 10px;
        padding: 4px 8px;
    }
}



// ConversationListItem
.conversation-list-item {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    overflow-x: hidden;

    > div {
        display: flex;

        &:first-child {
            width: 100%;
        }
    }

    &.conversation-active {
        background: #eeeef1;
    }
   
}
.conversation-list-item:hover {
    background: #eeeef1;
    cursor: pointer;
}
#conversation-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}
#conversation-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    width: 67%;
}

#conversation-badge {
    margin-left: -31px;
}

#conversation-title {
    font-size: 14px;
    font-weight: bold;
    margin: 0;

    text-overflow: ellipsis;
    overflow: hidden;
}
.conversation-snippet {
    font-size: 14px;
    color: #888;
    margin: 0;
}


@media (max-width: 767px){
    #messenger {
        display: flex;
        flex-direction: column;
        
        > [id$=contacts] {
            width: 100%;
            height: 40%;
        }

       
    }

    #messages {
        border-left: none;
        border-top: solid lightgray 1px;
        max-height: 24.2rem;
    }
    
    #group-message-list {
        height: calc(100% - 100px - 60px);
    }

    #compose {
        width: 69.2%;
        position: absolute;
        bottom: 5rem;

        @media (orientation: landscape){
            width: 75%;
        }
    }
}

@media (max-width: 576px) {

    #messages {
        max-height: calc(100vh - 31rem);
        border-left: none;
    }

    #compose {
        width: 90%;
        margin-left: -0.6rem;
    }    
}