.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.unsupported-file-type {
  font-size: 0.875em; /* Smaller font size */
  color: #666; /* Grey color for the text */
  text-align: center;
  margin: auto; /* Center the text within its container */
  padding: 20px; /* Add some padding for spacing */
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.custom-modal-content {
  position: relative;
  background: white;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 85%;
  height: 85%;
  max-height: 85%;
  overflow: auto;
  margin: 0 12px;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 768px) {
    width: 95%;
    height: 80%;
    max-height: 80%;
  }
}

.custom-modal-close {
  position: fixed;
  top: 15px;
  right: 15px;
  border: none;
  font-size: 2em;
  cursor: pointer;
  z-index: 1100; /* Ensure close button is above the modal */
  border-radius: 50%;
  background-color: whitesmoke;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  padding: 0;
}

.custom-modal-close span {
  display: inline-block;
  margin-top: -4px; /* Moves the × character up slightly */
}

.image-viewer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; /* Padding to ensure the image doesn't touch the edge */
  overflow: auto;
  flex: 1;
}

.image-viewer img {
  max-width: 100%;
  height: auto;
  display: block;
}

.pdf-viewer {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Loading spinner styles */
.loading-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex: 1;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #176AD6;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
