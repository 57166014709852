.layout {
  display: flex;

  ul {
    list-style-type: none;
  }

  a {
    text-decoration: none;
    color: black;
  }

  .main-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .content-wrapper {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: hidden;
    
    @media (max-width: 768px) {
      padding: 10px 15px;
    }

    .children-container {
      flex: 1;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      .nav {
        padding-top: 10px;
      }
      
      @media (max-width: 1024px) {
        padding: 5px;
      }
      
      @media (max-width: 768px) {
        padding-bottom: 20px;
      }

      #f-main {
        background-color: transparent;
        justify-content: center;

        & > div:nth-child(1) {
          flex-direction: row;
        }

      }

    }

    @media (max-width: 576px) {
      padding: 0;
    }
  }

  .header {
    background-color: rgba(227, 232, 235, 0.5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 25px; /* Increased top/bottom padding */
    height: 100px;
    gap: 6px;
    border-radius: 5px;
    position: sticky;
    top: 0;
    z-index: 100;
    transition: height 0.3s ease-in-out;
    
    @media (max-width: 576px) {
      padding: 20px 15px; /* More padding on mobile view */
    }

    div.popup {
      position: absolute;
      top: 100px;
      right: 66px;
      background-color: white;
      border: 1px solid #ccc;
      z-index: 100;
      border-radius: 10px;

      @media (max-width: 1024px) {
        right: 44px;
      }

      @media (max-width: 576px) {
        top: 70px;
        right: 26px;
      }

      .popup-sidebar {
        display: none;
        flex-direction: column;
        margin: 0;
        padding: 0;

        @media (max-width: 576px) {
          display: flex;
        }
      }

      .popup-item {
        display: flex;
        align-items: center;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;

        p {
          margin-right: 10px;
          margin-bottom: 0;
        }

        svg {
          widows: 20px;
          height: 20px;
          margin-right: 10px;
        }

        &:hover,
        &:focus {
          background-color: #176AD6;

          svg {
            color: white;
          }

          p {
            color: white;
          }
        }
      }
    }

    @media (max-width: 1024px) {
      flex-direction: column-reverse;
      height: auto; /* Allow header to adjust based on content */
      min-height: 140px;
      gap: 12px; /* Fixed gap for more consistent spacing */
      padding-top: 15px; /* More top padding */
      padding-bottom: 15px; /* More bottom padding */

      .input-wrapper[data-closeim] {
        width: 100%;
        margin: 10px 0; /* Add margin for better spacing */
      }

      .user-wrapper {
        width: 100%;
        padding: 10px 0 5px; /* Add padding for better spacing */

        .user__content {
          width: 100%;
          justify-content: space-between;
        }
      }
    }

    svg {
      width: 30px;
      height: 30px;
      
      &.menu-icon {
        width: 32px;
        height: 32px;
        color: #176AD6;
      }
    }

    & .input-wrapper {
      width: 70%;
      position: relative;
      margin: 10px 0;

      @media (max-width: 576px) {
        width: 100%;
      }

      .input-container {
        input {
          border: 1px solid #bbb8b8;
          border-radius: 40px;
          padding: 5px 10px;
          background-color: transparent;
          outline: none;
          width: 100%;
          padding: 10px 20px;
          font-size: clamp(0.875rem, 0.781vw + 0.719rem, 1rem);
        }

        .input-options-wrapper {
          text-align-last: center;

          position: absolute;

          width: 100%;

          background-color: white;
          border: 1px solid rgb(222, 226, 230);
          border-radius: 10px;

          padding: 0.3rem 1rem;
          margin-top: 0.7rem;
          transition: all 0.3s ease-in-out;

          z-index: 1;

          max-height: 10.8rem;
          overflow-y: auto;

          .files-list-item-wrapper {
            display: flex;
            justify-content: space-between;

            font-size: 15px;
            width: 100%;

            border-bottom: 1px solid rgb(222, 226, 230);

            padding: 3px 5px;

            cursor: pointer;
            transition: all 0.1s ease-in-out;

            white-space: nowrap;

            .files-list-item-name {
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: 78%;
            }

            &:hover {
              background-color: rgb(222, 226, 230);

              .files-list-item-wrapper {
                border-bottom: 1px solid white;
              }
            }
          }

          .files-list-item-wrapper:last-child {
            border-bottom: 0;
          }
        }
      }

      svg {
        width: clamp(1.25rem, 3.906vw + 0.469rem, 1.875rem);
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .user-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 6px;
      margin-left: 25px;
      
      @media (max-width: 576px) {
        justify-content: space-between; /* Logo on left, rest on right */
      }

      .user__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        
        @media (max-width: 1024px) {
          gap: 15px;
          padding: 10px 0;
          margin-right: 10px;
          align-items: center;
          justify-content: flex-end;
        }
        
        @media (max-width: 576px) {
          gap: 20px;
          width: auto;
          align-items: center;
        }
      }

      svg {
        cursor: pointer;
      }

      .user__logo-mobile {
        display: none;

        @media (max-width: 576px) {
          display: block;
          width: 100%;
          max-width: clamp(2.5rem, 7.813vw + 0.938rem, 3.75rem);
          margin: 5px 0 5px 5px;
        }
      }

      @media (max-width: 576px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .user__data-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        
        @media (max-width: 576px) {
          min-width: 80px;
          max-width: 100px;
        }

        & > div {
          position: relative;

          span {
            font-weight: bold;
            height: min-content;
            
            @media (max-width: 576px) {
              max-width: 80px;
              overflow: hidden;
              white-space: normal;
              display: block;
              font-size: 14px;
              line-height: 1.2;
            }
          }

          p {
            font-size: 11px;
            color: #bbb8b8;
            font-weight: bold;
            position: absolute;
            top: 0;
            margin: clamp(0.75rem, 0.568vw + 0.636rem, 1.063rem) 0 0;
          }
        }
        
        & .user__settings {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          padding: 5px;
          border-radius: 5px;
          
          @media (max-width: 576px) {
            padding: 3px;
            margin-right: -8px;
          }
          
          &:hover {
            background-color: rgba(23, 106, 214, 0.1);
          }
        }

        @media (max-width: 1024px) {
          & .user__settings {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            cursor: pointer;
            padding: 5px;
            border-radius: 5px;
            
            &:hover {
              background-color: rgba(23, 106, 214, 0.1);
            }
            
            .menu-icon {
              width: 30px;
              height: 30px;
              color: #176AD6;
            }
          }
        }
      }

      #profileP-container {
        width: clamp(2.5rem, 7.813vw + 0.938rem, 3.75rem);
        height: clamp(2.5rem, 7.813vw + 0.938rem, 3.75rem);
        border: solid rgb(96, 132, 190) 3px;
        background-color: white;
        padding: 1px;
        
        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        pointer-events: none;

        @media (max-width: 1024px) {
          pointer-events: none;
          margin-right: 15px; /* Add space on the right */
          width: 50px;
          height: 50px;
        }
        
        @media (max-width: 576px) {
          margin: 5px 5px 5px -5px; /* Move profile pic closer to burger */
          width: 45px;
          height: 45px;
        }
      }
    }
  }

  .sidebar {
    width: 25%;
    max-width: 220px;
    position: sticky;
    top: 0;
    background-color: rgba(227, 232, 235, 0.5);
    height: 100vh;
    text-align: center;
    padding: 20px 0;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      padding: 10px 0;
    }
    
    &__logo-wrapper {
      padding: 10px 0 20px;
      width: 100%;
      
      @media (max-height: 600px) {
        padding: 5px 0 10px;
      }
    }

    &__logo {
      width: auto;
      height: 100px;
      margin: 0 auto;
      
      @media (max-width: 768px), (max-height: 600px) {
        display: none;
      }
    }

    &__logo-no-text {
      display: none;
      width: auto;
      height: 60px;
      max-width: 80px;
      margin: 0 auto;

      @media (max-width: 768px), (max-height: 600px) {
        display: block;
      }
      
      @media (max-height: 450px) {
        height: 40px;
      }
    }

    &__logo-desc {
      font-size: 14px;
      margin-top: 10px;
      color: gray;
      text-align: center;
      
      @media (max-height: 450px) {
        font-size: 12px;
        margin-top: 5px;
      }
      
      @media (max-height: 350px) {
        display: none;
      }
    }

    a {
      display: block;
      width: 100%;
    }

    ul {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 85%;
      margin: 0 auto;
      padding: 20px 0;
      overflow-y: auto;
      justify-content: center;
      
      @media (max-width: 768px) {
        width: 100%;
        align-items: center;
        padding: 10px 0;
      }

      li {
        cursor: pointer;
        padding: 8px 10px;
        border-radius: 10px;
        margin-bottom: 15px;
        text-align: left;

        @media (max-width: 768px) {
          width: 46px;
          height: 46px;
          margin: 10px auto;
          padding: 0;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }
        
        @media (max-height: 450px) {
          margin: 5px auto;
          padding: 5px;
        }

        &.active,
        &:hover,
        &:focus {
          background-color: #176AD6;

          span {
            color: white;
          }

          svg {
            color: white;
          }
        }

        span {
          margin-left: clamp(0.625rem, 1.389vw + 0.208rem, 1.25rem);
          font-size: 14px;

          @media (max-width: 768px) {
            display: none;
          }
        }

        svg {
          width: 25px;
          height: 25px;
          margin-left: 10px;
          
          @media (max-width: 768px) {
            margin: 0;
            width: 28px;
            height: 28px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    .sign-out {
      display: none; /* Hide sign out from sidebar */
    }

    @media (max-width: 576px) {
      display: none;
    }
  }

  #file-list {
    padding-right: 10px;

    .icon-container {
      position: relative;
      display: inline-block;

      .tooltip-text {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: white;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 100%;
        left: 50%;
        margin-left: -60px; // Adjust to ensure the tooltip is centered

        /* Tooltip arrow */
        &:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: black transparent transparent transparent;
        }
      }

      &:hover .tooltip-text {
        visibility: visible;
      }
    }

    .file_list_info_icon {
      width: 1.4rem;
      margin-left: 1rem;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      position: absolute;
      top: 7px;
      right: 1rem;
      cursor: pointer;
    }

    .index-status-column, th.index-status-column {
      display: table-cell; // Ensuring it's displayed by default

      @media (max-width: 768px) { // Adjust this value based on your mobile breakpoint
        display: none; // Hides the column on mobile devices
      }
    }

    .icon-indexed {
      width: 20px; // Set the icon size to fit your design
      height: 20px; // Ensure the icon's height matches its width
      vertical-align: middle; // Align the icon vertically with the adjacent text
      margin-right: 5px; // Optional: Adjust spacing to suit your layout
      color: green; // Set a default color, adjust as needed
    }

    .icon-pending {
      width: 20px; // Set the icon size to fit your design
      height: 20px; // Ensure the icon's height matches its width
      vertical-align: middle; // Align the icon vertically with the adjacent text
      margin-right: 5px; // Optional: Adjust spacing to suit your layout
      color: orange; // Set a default color, adjust as needed
    }

    .icon-error {
      width: 20px; /* Set the icon size to fit your design */
      height: 20px; /* Ensure the icon's height matches its width */
      vertical-align: middle; /* Align the icon vertically with the adjacent text */
      margin-right: 5px; /* Optional: Adjust spacing to suit your layout */
      color: red; /* Set a default color, adjust as needed */
    }

    .icon-not-supported {
      width: 20px; /* Set the icon size to fit your design */
      height: 20px; /* Ensure the icon's height matches its width */
      vertical-align: middle; /* Align the icon vertically with the adjacent text */
      margin-right: 5px; /* Optional: Adjust spacing to suit your layout */
      color: gray; /* Set a default color, adjust as needed */
    }

    // Ensures icon visibility on row hover
    .react-table-lite-row:hover {
      .file_list_info_icon {
        opacity: 1;
      }
    }
  }


  #file-line {
    font-size: clamp(0.875rem, 0.227vw + 0.83rem, 1rem);
    white-space: nowrap;
    margin-bottom: 6px;
    border: none;

    div:nth-child(1) {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    @media (max-width: 768px) {
      grid-template-columns: 7fr 1.5fr 0.5fr;
      grid-template-rows: 1fr;

      div:nth-child(2) {
        display: none;
      }
    }
  }
}
