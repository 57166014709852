@font-face {
	font-family: "Open Sans";
	src: url('./styles/fonts/Open-sans/static/OpenSans-Regular.ttf') format("ttf");
	font-weight: normal;
	font-style: normal;
}

html, body {
	height: 100%;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	font-family: -apple-system, BlinkMacSystemFont, "Open Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	height: 100%;
	overflow-x: hidden;
}