#profile-container {
	background-color: white;
	padding: 10px;
	border-radius: 10px;
	overflow: hidden;
	height: auto;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}

// Consistent heading style from dashboard
h2.section-title, .section-title {
	font-size: 18px;
	font-weight: bold;
	text-decoration: underline;
	text-decoration-color: #176AD6;
	text-underline-offset: 0.4em;
	margin-bottom: 20px;
	text-align: left;

	@media (max-width:576px) {
		text-align: center;
	}
}

#profileP-container {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	border: solid black 3px;
	padding: 0;
	margin-left: 20px;
	position: relative;

	>div:first-child {
		border-radius: 50%;
		overflow: hidden;
	}

	div>img {
		object-fit: cover;
		width: 100%;
		aspect-ratio: 1/1;
	}
	
	/* Only small on mobile */
	@media (max-width: 767px) {
		width: 80px;
		height: 80px;
		border: solid black 2px;
	}
}

#editPic {
	position: absolute;
	background-color: lightgray;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: solid black 2px;
	bottom: -6px;
	right: -12px;
	z-index: 2;
	object-fit: cover;
	display: flex;
	font-size: 18px;

	>i {
		margin-left: 9px;
		line-height: 36px;
	}
	
	/* Smaller on mobile */
	@media (max-width: 767px) {
		width: 30px;
		height: 30px;
		right: -6px;
		font-size: 16px;
		
		>i {
			margin-left: 7px;
			line-height: 28px;
		}
	}
}

#edit-modal {
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
	position: absolute;
	left: 145px;
	top: 20px;
	
	/* Adjust position on mobile */
	@media (max-width: 767px) {
		left: 105px;
		top: 8px;
	}

	>#pp-file-input {
		grid-row: 1 / span 1;
		grid-column: 1 / span 2;
		width: fit-content;
	}

	>#new-pic-submit {
		grid-row: 2 / span 1;
		grid-column: 1 / span 1;
	}

	>#remove-pic {
		grid-row: 2 / span 1;
		grid-column: 2 / span 1;
	}

	>Button {
		width: max-content;
	}
}


#profile-top-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 15px;
	margin-bottom: 40px;
}

#go-home {
	width: min-content;
	height: min-content;
	align-self: center;
	margin-right: 12px;
}

.btn-primary, .btn-secondary, .btn-danger {
    transition: all 0.3s ease;
    border-radius: 6px;
    font-weight: 500;
    padding: 8px 12px;
    font-size: 0.9rem;
}

.btn-primary {
    background-color: #176AD6;
    border-color: #176AD6;
    &:hover {
        background-color: #1259b2;
        border-color: #1259b2;
    }
}

.btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
    &:hover {
        background-color: #5a6268;
        border-color: #5a6268;
    }
}

.btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
    &:hover {
        background-color: #bd2130;
        border-color: #bd2130;
    }
}

#profile-save {
	margin-bottom: 18px;
}

#pp-pw-btns {
	display: flex;
	flex-direction: row;
	margin-left: 0px;

	&>Button {
		width: max-content;
		margin-right: 10px;
	}
}

#profile-delete {
	display: flex;
	margin-right: 18px;
	margin-bottom: 5px;
	justify-content: flex-end;

	>Button {
		width: max-content;
		font-size: 18px;
	}
}


@media (max-width: 640px) {
	#profile-container {
		/* Adjusted padding for mobile */
		padding: 10px;
	}
	
	#profile-top-row {
		margin-top: 20px;
		margin-bottom: 35px;
	}

	#edit-modal {
		top: 95px;
		left: 0px;
	}
}

// Subscription and invoices styling
.section-divider {
    border-top: 1px solid #dee2e6;
}

.section-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-decoration: underline;
    text-decoration-color: #176AD6;
    text-underline-offset: 0.4em;
    text-align: left;
}

.subscription-section, .invoices-section {
    background-color: white;
    padding: 0;
    margin-bottom: 20px;
    width: 100%;
}

.subscription-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
}

.info-row {
    display: flex;
    align-items: center;
}

.info-label {
    font-weight: 600;
    width: 120px;
    color: #555;
}

.info-value {
    font-weight: 500;
}

.status-active {
    color: #28a745;
}

.status-canceled, .status-cancelled {
    color: #dc3545;
}

.status-badge {
    display: inline-block;
    padding: 10px;
    border-radius: 15px;
    font-size: 0.85rem;
    font-weight: 500;
    text-transform: capitalize;
}

.status-paid {
    background-color: #d4edda;
    color: #155724;
}

.status-pending {
    background-color: #fff3cd;
    color: #856404;
}

.invoice-table {
    overflow: hidden;
}

.invoice-link {
    color: #176AD6;
    text-decoration: none;
    font-weight: 500;
    
    &:hover {
        text-decoration: underline;
    }
}

.loading-indicator {
    text-align: left;
    color: #6c757d;
}

.subscription-empty, .invoices-empty {
    text-align: left;
    color: #6c757d;
}

@media (max-width: 768px) {
    .info-row {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
    }
    
    .info-label {
        width: 100%;
    }
}

.danger-zone {
    background-color: white;
    padding: 0;
    margin-bottom: 20px;
    width: 100%;
}

.danger-zone-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #dc3545;
    text-decoration: underline;
    text-decoration-color: #dc3545;
    text-underline-offset: 0.4em;
    text-align: left;
}

.danger-description {
    margin-bottom: 15px;
    color: #555;
}

.delete-account-btn {
    display: inline-block;
    width: auto;
}

@media (max-width: 475px) {
	#profile-main {
		margin: 20px auto;
		overflow: visible;
	}
    
    .subscription-section, .invoices-section {
        padding: 0;
        width: 100%;
    }
    
    .danger-zone {
        padding: 0;
        width: 100%;
    }
    
    .invoice-table {
        width: 100%;
        display: block;
        overflow-x: auto;
    }
}