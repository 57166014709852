// Font import
@import './vars.scss';

.auth-wrapper {
  position: relative;
  min-height: 100vh;
  overflow-y: auto;
  background-color: #fff;
  margin: 0;
  padding: 0;
}


/* Hide the main footer in the login wrapper */
.auth-login-wrapper > .footer {
  display: none;
}

/* Style the footer */
.auth-form-footer {
  position: relative;
  left: 50%;
  width: 50%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: rgba(227, 232, 235, 0.5);
  z-index: 10;
  margin: 0;
  position: fixed;
  bottom: 0;
}

.auth-form-footer a {
  color: #505050;
  text-decoration: none;
  font-size: 0.9rem;
}

.auth-form-footer a:hover {
  text-decoration: underline;
}

.auth-page {
  display: flex;
  min-height: 100vh;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  position: relative;
  box-sizing: border-box;
}

// Left side - App preview
.auth-preview {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: calc(100vh - 70px);
  width: 50%;
  
  // Background image
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../resources/auth_sideimage.png');
    background-size: cover;
    background-position: center;
    opacity: 0.8;
    z-index: 1;
  }
  
  // Darkened overlay
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
  
  // Logo at the top
  &__logo {
    margin-bottom: 2rem;
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    
    img {
      width: auto;
      height: 250px;
    }
  }
  
  // Text content
  &__content {
    width: 80%;
    text-align: center;
    position: relative;
    z-index: 3; // Above the background
    padding: 2rem;
    
    h2 {
      margin-bottom: 1.5rem;
      font-weight: 600;
      font-size: 2.5rem;
      color: white;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    }
    
    p {
      font-size: 1.3rem;
      line-height: 1.6;
      color: white;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    }
    
    .company-info {
      font-size: 1rem;
      line-height: 1.4;
    }
  }
}

// Right side - Authentication
.auth-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 70px);
  padding-bottom: 70px;
  width: 50%;
  
  &__container {
    width: 100%;
    max-width: 420px;
  }
  
  &__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 2.5rem;
    
    img {
      width: auto;
      height: 80px;
    }
  }
  
  h3 {
    font-weight: 600;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .alternatives-container {
    display: flex;
    justify-content: space-between;
    margin: 1.5rem 0;
    
    a, span {
      color: #000;
      text-decoration: none;
      cursor: pointer;
      transition: color 0.2s;
      
      &:hover {
        color: #444;
      }
    }
    
    .forgot-password-link {
      color: #000;
      
      &:hover {
        color: #444;
      }
    }
  }
  
  .auth-button {
    width: 100%;
    padding: 0.8rem;
    background-color: #176AD6;
    border: none;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    margin-top: 1rem;
    transition: background-color 0.3s;
    
    &:hover {
      background-color: darken(#176AD6, 10%);
    }
    
    &:disabled {
      background-color: lighten(#176AD6, 20%);
      cursor: not-allowed;
    }
  }
}

// Store download buttons
.download-buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
  margin-bottom: 4rem;
  
  a {
    margin: 0 10px;
  }
  
  img {
    height: 50px;
    width: auto;
    transition: opacity 0.2s;
    
    &:hover {
      opacity: 0.9;
    }
  }
}

// Password reset modal styling
.modal-content {
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0,0,0,0.1);
}

#pwrp-sendBtn {
  transition: background-color 0.3s;
}

// Terms and conditions checkbox styling
.terms-checkbox-container {
  margin: 1rem 0;
  
  .form-check {
    display: flex;
    align-items: flex-start;
    
    .form-check-input {
      margin-right: 10px;
      margin-top: 3px;
      cursor: pointer;
    }
    
    .form-check-label {
      font-size: 0.9rem;
      cursor: pointer;
      
      a {
        color: #176AD6;
        text-decoration: underline;
        
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  
  .error-message {
    color: #dc3545;
    font-size: 0.8rem;
    margin-top: 5px;
  }
}

// Resend confirmation email styling
.resend-confirmation {
  margin-top: 1.5rem;
  padding: 1rem;
  border-radius: 4px;
  background-color: rgba(227, 232, 235, 0.7);
  text-align: center;
  
  p {
    font-size: 0.9rem;
    margin-bottom: 0.75rem;
    color: #505050;
  }
  
  .resend-button {
    padding: 0.5rem 1rem;
    background-color: #176AD6;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 0.9rem;
    transition: background-color 0.3s;
    
    &:hover {
      background-color: darken(#176AD6, 10%);
    }
    
    &:disabled {
      background-color: lighten(#176AD6, 20%);
      cursor: not-allowed;
    }
  }
}

// Footer
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  min-height: 60px;
  background-color: rgba(227, 232, 235, 0.5);
  position: relative;
  margin-top: 20px;
  width: 100%;
  
  a {
    color: #505050;
    text-decoration: none;
    font-size: 0.9rem;
    
    &:hover {
      text-decoration: underline;
    }
  }
}

// Responsive styles - tablet layout
@media (max-width: 991px) and (min-width: 768px) {
  .auth-page {
    flex-direction: row;
  }
  
  .auth-preview {
    flex: 0.8; // Make it slightly smaller on tablets
    width: 40%;
    
    &__content {
      width: 90%;
      
      p {
        font-size: 1.1rem;
      }
    }
  }
  
  .auth-form {
    flex: 1.2;
    width: 60%;
    display: flex;
    justify-content: center;
    
    &__container {
      max-width: 380px;
    }
  }
  
  // Adjust footer
  .auth-form-footer {
    position: fixed;
    left: 40%;
    width: 60%;
    bottom: 0;
  }
}

// Mobile layout - hide left side
@media (max-width: 767px) {
  .auth-wrapper {
    min-height: unset;
  }
  .auth-page {
    flex-direction: column;
    min-height: unset;
  }
  
  .auth-preview {
    display: none;
    width: 100%;
    
    &::before, &::after {
      display: none;
    }
  }
  
  .auth-form {
    flex: 1;
    min-height: unset;
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 20px;
    width: 100%;
    
    &__container {
      max-width: 380px;
    }
  }
  
  // Full width footer for mobile
  .auth-form-footer {
    position: relative;
    left: 0;
    width: 100%;
    margin-top: 30px;
    background-color: transparent;
  }
}

@media (max-width: 576px) {
  .auth-form {
    padding: 5rem 1rem 0;
    
    &__logo img {
      height: 80px;
    }
  }
  
  .download-buttons-container {
    flex-direction: column;
    align-items: center;
    
    a {
      margin: 0.5rem 0;
    }
  }
  
  .footer {
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 0;
    background-color: transparent;
  }
}