
.content > .container {
    padding: 0;
    width: 100%;
}

.drop-container {
    display: flex;
    justify-content: center;
}
.domain-chooser {
    width: max-content;
    margin-bottom: 25px;
}

.source-cell {
    font-weight: 500;
    color: #333;
}

/* If using an icon for Source */
.source-icon {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    vertical-align: middle;
}

#pagination-container {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;

    .page_limit-container {
        position: absolute;
        width: max-content;
        left: 0;
    }

    @media (max-width: 475px){
        grid-template-columns: 1fr;
        justify-items: center;
        gap: 10px;
        overflow-x: visible;
        justify-content: flex-end;
        width: 100%;
    }

    select {
        margin-top: 5px;
        width: 85px;
        border: solid darkslategray 1px;
        cursor: pointer;
    }

    nav {
        justify-self: center;
        padding-bottom: 5px;
        margin-top: 28px;

        ul {
            margin: 0;
            cursor: pointer;
        }
    }
}

#sorting-div {
    display: flex;
    align-content: center;
    margin-bottom: 15px;

    h3 {
        margin-bottom: 0;
        margin-right: 30px;
        line-height: 1.3;
    }
    button {
        padding: 0 8px;
    }
}
#sort-menu {
    min-width: 145px;
}
#sort-direction {
    display: flex;
    justify-content: space-between;
}


#file-header {
    display: grid;
    grid-template-columns: 7fr 2.5fr 1.5fr 1fr;
    padding-left: 8px;

    p {
        margin: 0;
    }

    @media (max-width: 640px){
        display: none;
    }
}

.list-group {
    height: auto;
    overflow: visible;
    width: 100%;
    margin-bottom: 20px;
}

#file-line {
    display: grid;
    grid-template-columns: 7fr 2.5fr 1.5fr 1fr;
    max-width: 100%;

    @media (max-width: 640px){
        grid-template-columns: 1fr;
        margin-bottom: 10px;
        border-bottom: black 2px solid;
    }
}

#fl-item {
    display: flex;
    justify-content: center;
    position: relative;

    @media (max-width: 640px){
        justify-content: flex-end;
    }
}
#fl-options {
    width: min-content;
    height: min-content;
    margin: 0px;
    padding: 1px 8px;
    background-color: darkgray;
}
#fl-option-btns {
    display: grid;
    grid-template-rows: 1fr;
    row-gap: 5px;
    z-index: 100;
    border-radius: 8px;
    padding: 10px;
    position: absolute;
    right: 90%;

    i{
      margin-right: 2px;
    }

    @media (min-width: 1000px) {
        right: 80%;
    }

    top: -60px;
    background-color: lightgrey;
    width: 135px;

    @media (max-width: 640px){
        right: 15%;
        top: -125px;
    }

    @media (max-width: 475px){
        right: 25%;
    }

    > Button {
        font-size: 14px;
        font-weight: 550;
    }
}


#fs-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    overflow-x: hidden;

    @media (max-width: 475px){
        padding-left: 0px;
        flex-direction: row-reverse;
        justify-content: start;

        :nth-child(1) {
            margin-right: 5px;
        }
    }
}
#fs-stop-share {
    margin: 0;
    padding: 0px 5px;
}

// fcm: file context-menu
#fcm {
    position: fixed;
    background: lightgray;
    border: 1px solid black;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    z-index: 99999;
    padding: 5px;
    width: 65px;

    #Rename:hover {
        background-color: rgba(255, 255, 255, 0.5);
        text-decoration: underline;
        font-weight: 550;
        cursor: pointer;
    }
    #Download:hover, #Share:hover {
        background-color: rgba(15, 113, 242, 0.4);
        text-decoration: underline;
        font-weight: 550;
        cursor: pointer;
    }
    #Cancel:hover, #Delete:hover{
        background-color: rgba(226, 28, 48, 0.5);
        text-decoration: underline;
        font-weight: 550;
        cursor: pointer;
    }
}


// create-file, currently not in use
// .cf-container {
//     width: 100%;
//     padding: 5px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     border-radius: 8px;

//     background-color: lightblue;
//     :hover {
//         cursor: pointer;
//     }

//     &#highlighted {
//         border: solid black 1px;
//         box-shadow: 0 0 5px 0 black;
//     }
// }
// .cf-container > img {
//     width: 80%;
//     aspect-ratio: 1/1;
// }
// .cf-container > p {
//     margin-top: 10px;
//     text-align: center;
//     font-size: 20px;
// }

.col > .btn {
    margin-left: 15px;
}

.MUB_Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-bottom: 15px;
    min-height: 8rem;
    flex-wrap: wrap;


    .mub-container {
        display: flex;
        // flex: 1;
    }

    .MUB_btn_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 1rem;
        gap: 1rem;

        @media (max-width: 1030px) {
            margin-left: auto;
        }
    }

    .mub-text {
        //max-height: 8rem;
        overflow: auto;
    }
    
    .mub-logo {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);

        width: 5rem;
        height: 5rem;
        margin-top: 1rem;
    }

    .mub-thought {
        display: flex;
        background-color: #F0F3F5;
        padding: 20px;
        border-radius: 30px;
        min-width: 15rem;
        min-height: 4rem;
        margin: 20px;
        position: relative;
        align-items: center;
        justify-content: center;
        text-align: center;

        // opacity: 0;
        transition: all 0.5s ease-in-out;

        &.isMUBloading {
            .dot-elastic {
                animation: dot-elastic 1s infinite linear;


                &::before {
                    animation: dot-elastic-before 1s infinite linear;
                }

                &::after {
                    animation: dot-elastic-after 1s infinite linear;
                }
            }
        }

        &::before,
        &::after {
            content:"";
            background-color: #F0F3F5;
            border-radius:50%;
            display:block;
            position:absolute;
            z-index:-1;
        }


        &::before {

            width: 54px;
            height: 44px;
            top: -12px;
            left: 28px;
            box-shadow: -48px 41px 0 -12px #F0F3F5;

        }


        &::after {
            bottom:-10px;
            right:26px;
            width:30px;
            height:30px;
            box-shadow:40px -34px 0 0 #F0F3F5,
                        -28px -6px 0 -2px #F0F3F5,
                        -24px 17px 0 -6px #F0F3F5,
                        -5px 25px 0 -10px #F0F3F5;
        }


        @media (max-width: 576px) {
            min-width: calc(100vw - 19rem);
        }
    }

    .dot-elastic {
        position: relative;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #176AD6;
        color: #176AD6;
        // animation: dot-elastic 1s infinite linear;

        &::before,
        &::after {
            content: "";
            display: inline-block;
            position: absolute;
            top: 0;
        }

        &::before {
            left: -15px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: #176AD6;
            color: #176AD6;
            // animation: dot-elastic-before 1s infinite linear;
        }

        &::after {
            left: 15px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: #176AD6;
            color: #176AD6;
            // animation: dot-elastic-after 1s infinite linear;
        }


        
    }
        
}

@keyframes dot-elastic-before {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1.5);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
}

@keyframes dot-elastic {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 1.5);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
}

@keyframes dot-elastic-after {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
}

