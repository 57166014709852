.toggleIcon {
	color: #176AD6;
}

body {
	min-height: 100dvh;
	max-width: 100vw;
	margin: 0;
	overflow: hidden; // Disable scrolling on body
}

#layout {
	display: flex;
	overflow: hidden; // Disable scrolling on layout
	height: calc(100dvh - var(--footer-height));

	// Style in common for sidebars and main area:
	.content {
		overflow: hidden;
		box-sizing: border-box;
		max-height: calc(100dvh - var(--footer-height) - 75px);
		display: flex;
		flex-direction: column;
	}

	#left .content {
		height: fit-content;
	}
}

// Main area specific style:
#main {
	flex-grow: 1;

	.content {
		margin: 75px auto 0 auto;

		@media (orientation: landscape) {
			width: 75vw;
		}

		@media (orientation: portrait) {
			width: 65vw;
		}
	}
}

.profile-pic {
	z-index: 10;
	margin-left: 15px;
	margin-top: 15px;
	position: fixed;
	user-select: none;
	cursor: pointer;

	@media (max-width: 768px) {
		margin-left: 0px;
		margin-top: 10px;
	}

	>#profileP-container {
		max-width: 64px;
		max-height: 64px;
		border-radius: 50%;
		border: solid grey 3px;

		div>img {
			object-fit: cover;
			width: 100%;
		}
	}
}

// // Style for right sidebar:
// #right {
//   transition: width var(--secs);
//   width: 0;

//   @media (orientation: landscape) {
//     &.open {
//       width: calc(1% * var(--landscape-width));
//     }
//   }
//   @media (orientation: portrait) {
//     &.open {
//       width: calc(1% * var(--portrait-width));
//     }
//   }

//   .icon {
//     position: fixed;
//     z-index: 10;
//     width: var(--header-height);
//     height: var(--header-height);
//     line-height: var(--header-height);
//     font-size: var(--header-height);
//     text-align: center;
//     user-select: none;
//     cursor: pointer;
//     right: 0;
//   }

//   .sidebar {
//     background: var(--bg-right);
//     transition: transform var(--secs); // <-- Portrait AND landscape!
//   }
// }


// Left sidebar specific style:
#left {
	--landscape-width: 30;
	--portrait-width: 35;
	--secs: 0.5s;

	z-index: 5;
	width: 0;

	@media (orientation: landscape) {
		&.open {
			width: calc(0.6vw * var(--landscape-width));
		}
	}

	@media (orientation: portrait) {
		&.open {
			width: calc(0.7vw * var(--portrait-width));
		}
	}

	.sidebar {
		transition: transform var(--secs); // <-- Portrait AND landscape!

		&.closed {
			transform: translateX(-100%);
		}
	}
}

#profile-row {
	width: 100%;
	min-width: fit-content;
	margin: 0 0;
	border-bottom: solid 1px rgba($color: #fff, $alpha: 0.3);
	hyphens: auto;
}

.p-container {
	background: black;
	color: white;
	margin-top: 90px;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	padding: 10px 0;
	width: 100%;
}

.rows {
	margin-left: 5px;
	padding-bottom: 5px;
}