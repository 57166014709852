.app-promotion-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.98);
  z-index: 1000;
  display: none; /* Hide by default on all screens */
  justify-content: center;
  align-items: center;
  
  .app-promotion-content {
    max-width: 500px;
    padding: 30px;
    text-align: center;
    position: relative;
    
    h2 {
      margin-bottom: 15px;
      font-size: 1.8rem;
      color: #0066cc;
    }
    
    p {
      margin-bottom: 30px;
      font-size: 1.2rem;
      color: #333;
    }
    
    .close-button {
      position: fixed;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 1.2rem;
      color: #777;
      cursor: pointer;
      padding: 5px;
      line-height: 1;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1010;
      
      &:hover {
        color: #333;
      }
    }
    
    .app-store-links {
      display: flex;
      justify-content: center;
      gap: 20px;
      
      img {
        height: 50px;
        cursor: pointer;
      }
    }
  }
  
  /* Only show on mobile screens (not tablets) */
  @media (max-width: 576px) {
    display: flex !important; /* Force display on mobile */
    
    .app-promotion-content {
      width: 90%;
    }
    
    /* Adjust for smaller screens */
    @media (max-width: 480px) {
      .app-store-links {
        flex-direction: column;
        align-items: center;
        gap: 15px;
      }
    }
  }
}