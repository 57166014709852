/* CloudsPage.scss */

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    font-size: 1.2rem;
    color: #555;
}

.clouds-page {
    padding: 20px;

    h3 {
        margin-bottom: 20px;
    }

    .provider-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center; // Center the grid items
    }

    .service-item {
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 20px;
        width: 100%;
        max-width: 500px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        flex: 1 1 300px;

        .service-header {
            display: flex;
            align-items: center;
            justify-content: space-between; // Space between provider name and disconnect button
            margin-bottom: 15px;

            .logo-container {
                width: 40px;
                height: 40px;
                margin-right: 10px;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            h4 {
                margin: 0;
                flex-grow: 1;
                font-size: 18px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 200px; // Increased to accommodate longer names
            }

            .disconnect-button {
                // Bootstrap classes already handle most styling
                // Additional custom styles if needed
            }
        }

        .service-content {
            .connected-container {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .icon {
                    margin-right: 5px;
                }

                .connected-text {
                    font-size: 14px;
                    color: #16a34a; /* Green color */
                }
            }

            .add-folder-button {
                display: flex;
                align-items: center;
                white-space: nowrap; /* Keep text on one line */

                .icon {
                    margin-right: 5px;
                    flex-shrink: 0; /* Prevent icon from shrinking */
                    width: 16px; /* Ensure the icon has a fixed size */
                    height: 16px;
                }

                span {
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .folder-list {
                list-style-type: none;
                padding: 0;

                li {
                    margin-bottom: 10px;

                    .folder-item {
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        position: relative;

                        .icon-folder {
                            width: 20px;
                            height: 20px;
                            margin-right: 8px;
                            color: #ffc107; /* Folder icon color */
                            flex-shrink: 0;
                        }

                        .folder-path {
                            flex-grow: 1;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 100%;
                            cursor: pointer;
                        }

                        .file-count {
                            margin-left: 10px; /* Increased space */
                            font-size: 14px;
                            color: #666;
                            flex-shrink: 0;
                        }

                        .delete-folder-button {
                            background: none;
                            border: none;
                            cursor: pointer;
                            padding: 0;
                            margin-left: 10px;

                            .icon-red {
                                color: #e53e3e; /* Red color for delete icon */
                                width: 16px;
                                height: 16px;
                            }

                            &:hover .icon-red {
                                color: #c53030; /* Darker red on hover */
                            }
                        }
                    }
                }
            }

            p {
                color: #666;
                font-size: 14px;
            }
        }

        .service-overlay {
            position: relative;
            cursor: not-allowed;

            .connect-button {
                width: 100%;
                padding: 8px 16px;
                color: white;
                border: none;
                border-radius: 5px;
                cursor: not-allowed;
                font-size: 14px;
                text-align: center;
                background-color: #ccc;

                &.disabled-button {
                    background-color: #ccc;
                    cursor: not-allowed;
                }

                &:hover {
                    opacity: 0.8;
                }
            }

            &:hover::after {
                content: attr(title);
                position: absolute;
                top: -30px;
                left: 50%;
                transform: translateX(-50%);
                background-color: #333;
                color: #fff;
                font-size: 12px;
                padding: 5px 10px;
                border-radius: 4px;
                white-space: nowrap;
            }
        }

        .connect-button {
            width: 100%;
            padding: 8px 16px;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 14px;
            text-align: center;

            &.onedrive-button {
                background-color: #176ad6; /* OneDrive blue */
            }

            &:hover {
                opacity: 0.8;
            }
        }

        /* Icon size adjustments */
        .icon-green {
            color: #16a34a; /* Green color */
            width: 16px;
            height: 16px;
        }

        .small-icon {
            width: 16px;
            height: 16px;
        }
    }
}
